import React from 'react'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Wrapper = styled.div`
  width: 100%;
  white-space: nowrap;

  .button {
    width: 105px;
    height: 36px;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    background: var(--light-gray) 0% 0% no-repeat padding-box;
    border: 1px solid #e9e9e9;

    &.-active {
      color: #fff;
      background: transparent linear-gradient(242deg, var(--color-primary) 0%, var(--color-secondary) 100%) 0% 0% no-repeat padding-box;
    }
  }
`

export default function CarouselButton({ categories, handleCate, currentCate }) {
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
    variableWidth: true,
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {categories.map((items, index) => (
          <div className='w-full mr-3' key={index}>
            <button onClick={() => handleCate(items.icon)}>
              <div className={`button ${currentCate === items.icon ? '-active' : ''}`}>
                {items.text}
              </div>
            </button>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}